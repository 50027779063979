import React, { createContext, useContext, useState } from 'react';
import { jwtDecode } from "jwt-decode";
import { post } from '../utils/Api';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        logout();
        return null;
      }
      return token;
    } else {
      return null;
    }
  });

  const login = async (username, password) => {
    try {
      const data = { email: username, password }
      const resp = await post('https://api.apihub.app.br/api/v1/user/login', data);
      const accessToken = resp?.data?.access_token ?? '';
      if (!accessToken.length) {
        throw new Error("Nao foi possivel logar");
      }

      setToken(accessToken);
      localStorage.setItem('token', accessToken);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const logout = () => {
    if (token) {
      setToken(null);
    }
    localStorage.removeItem('token');
  };

  return (
    <AuthContext.Provider value={{ token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
