import axios from "axios";

const axiosInstance = axios.create({
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
  maxBodyLength: Infinity
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("Erro na API:", error);
    return Promise.reject(error);
  }
);

export const post = async (url, data, headers = {}) => {
  try {
    const response = await axiosInstance.post(url, data, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const get = async (url, params = {}, headers = {}) => {
  try {
    const response = await axiosInstance.get(url, { params, headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const put = async (url, data, headers = {}) => {
  try {
    const response = await axiosInstance.put(url, data, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const del = async (url, headers = {}) => {
  try {
    const response = await axiosInstance.delete(url, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};
