const menu = [
  {
    heading: "Dashboards",
  },
  {
    icon: "dashboard",
    text: "Default Dashboard",
    link: "/",
    dashboard: true,
  },
  {
    icon: "speed",
    text: "Sales Dashboard",
    link: "/sales",
  },
  {
    heading: "Quality Manager",
  },
  {
    icon: "tile-thumb",
    text: "Administração",
    active: false,
    subMenu: [
      {
        text: "Fichas de monitoria",
        link: "/product-list",
      },
    ],
  },
  {
    icon: "call-alt",
    text: "Áudios",
    active: false,
    subMenu: [
      {
        text: "Upload dos áudios para um grupo",
        link: "/transaction-crypto",
      },
    ],
  },
  {
    icon: "check-circle",
    text: "Monitoria",
    active: false,
    subMenu: [
      {
        text: "Áudio para monitorar",
        link: "/invoice-list",
      },
    ],
  },
  {
    heading: "Acessos",
  },
  {
    icon: "users",
    text: "Usuários",
    link: "/user-list-regular",
    dashboard: true,
  },
  {
    icon: "user",
    text: "Perfil de acesso",
    link: "/user-profile-regular",
    dashboard: false,
  },
  {
    heading: "Configurações",
  },
  {
    icon: "card-view",
    text: "Grupos",
    link: "user-contact-card",
    dashboard: false,
  },
  {
    icon: "setting-alt",
    text: "Gerais",
    link: "user-details-regular/1",
    dashboard: false,
  },
  {
    heading: "Conta",
  },
  {
    icon: "growth",
    text: "Consumo",
    link: "/table-basic",
    dashboard: false,
  },
  {
    icon: "coin",
    text: "Bilhetagem",
    link: "/image-gallery",
    dashboard: false,
  },
  {
    icon: "opt-dot-alt",
    text: "Plano",
    link: "/pricing-table",
    dashboard: true,
  },
];
export default menu;
