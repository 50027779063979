import React from "react";
import { Link } from "react-router-dom";
import { Block, BlockContent, Button } from "../../components/Component";

const Error504Classic = () => {
  return (
    <>
      <Block className="nk-block-middle wide-xs mx-auto">
        <BlockContent className="nk-error-ld text-center">
          <h1 className="nk-error-head">504</h1>
          <h3 className="nk-error-title">Ops! Por que você está aqui?</h3>
          <p className="nk-error-text">
            Lamentamos o inconveniente. Parece que você está tentando acessar uma página que foi excluída ou nunca existiu.
          </p>
          <Link to={`${process.env.PUBLIC_URL}/`}>
            <Button color="primary" size="lg" className="mt-2">
              Voltar para a Página Inicial
            </Button>
          </Link>
        </BlockContent>
      </Block>
    </>
  );
};

export default Error504Classic;
