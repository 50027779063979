import Router from "./route/Index";

import ThemeProvider from "./layout/provider/Theme";
import { AuthProvider } from './utils/AuthContext';

const App = () => {
  return (
    <ThemeProvider>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </ThemeProvider>
  );
};
export default App;